/* * {
  cursor: default;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.amcharts-line-pernament {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 500%;
  /*stroke-dasharray: 0;*/ /* fixes IE prob */
  /*stroke-dashoffset: 0;*/ /* fixes IE prob */
  -webkit-animation: am-line-pernament 45s;
  animation: am-line-pernament 45s;
}
@-webkit-keyframes am-line-pernament {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}
@keyframes am-line-pernament {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}

.amcharts-line-infinite {
  stroke-dasharray: 20%;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-line-infinite 75s linear infinite;
  animation: am-line-infinite 75s linear infinite;
}

@-webkit-keyframes am-line-infinite {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes am-line-infinite {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}

.amcharts-line-once {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 20%;
  visibility: hidden;
  /*stroke-dasharray: 0;*/ /* fixes IE prob */
  /*stroke-dashoffset: 0;*/ /* fixes IE prob */
  -webkit-animation: am-line-once 15s;
  animation: am-line-once 15s;
}
@-webkit-keyframes am-line-once {
  0% {
    stroke-dashoffset: 500%;
    visibility: inherit;
  }
  99% {
    visibility: inherit;
  }
  100% {
    stroke-dashoffset: 460%;
    visibility: hidden;
  }
}
@keyframes am-line-once {
  0% {
    stroke-dashoffset: 500%;
    visibility: inherit;
  }
  99% {
    visibility: inherit;
  }
  100% {
    stroke-dashoffset: 460%;
    visibility: hidden;
  }
}
